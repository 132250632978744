import React from "react"
import { useLocalStore } from "mobx-react-lite"
import { types } from "mobx-state-tree"

const UiStore = types
	.model({
		innerWidth: types.number
	})
	.actions(self => ({
		setInnerWidth(w: number) {
			self.innerWidth = w
		}
	}))
	.views(self => ({
		viewInnerWidth() {
			return self.innerWidth
		}
	}))

const UiStoreInstance = UiStore.create({
	innerWidth: 1440
})

const createRootStore = () => {
	const store = {
		ui: UiStoreInstance
	}
	return store
}

type RootStoreStoreType = ReturnType<typeof createRootStore>

const RootStoreContext = React.createContext<RootStoreStoreType | null>(null)

const RootStoreProvider: React.FC = ({ children }) => {
	const RootStoreStore = useLocalStore(createRootStore)

	return (
		<RootStoreContext.Provider value={RootStoreStore}>
			{children}
		</RootStoreContext.Provider>
	)
}

const useRootStore = () => {
	const store = React.useContext(RootStoreContext)
	if (!store) {
		throw Error("Root Store is null")
	}
	return store
}

export { RootStoreContext, RootStoreProvider, useRootStore }
