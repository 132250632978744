import React from "react"
import styled from "styled-components"
import DLButton from "../../common-elements/DLButton"
import { Link } from "react-router-dom"

const RequestDemoSection = () => {
	return (
		<StyledRequestDemoSection className="horizontal-container FC">
			<h2 className="section-title heading3">
				Try DATALOBBY services and see how smooth compliance works can
				be.
			</h2>
			<Link to="/request-demo">
				<DLButton color="primary">Request Demo</DLButton>
			</Link>
		</StyledRequestDemoSection>
	)
}

const StyledRequestDemoSection = styled.div`
	background-color: ${props => props.theme.primaryDarkDeep};
	color: ${props => props.theme.background};
	.section-title {
		width: 50vw;
		max-width: 800px;
		text-align: center;
		border: ${props => props.theme.shared.mockBorder};
		margin-bottom: 3rem;
	}
	@media (max-width: 400px) {
		.section-title {
			width: 72vw;
		}
	}
`
export default RequestDemoSection
