import React from "react"
import styled from "styled-components"
import orgProjectsArchived from "../../../../assets/screen-org-projects-archived.jpg"

export default function MainSectionA() {
	return (
		<StyledMainSectionA className="horizontal-container FR AC">
			<div className="informations FC">
				<h1 className="heading2">
					Project Management to Archive Management
				</h1>
				<div className="description body-text1">
					DATA LOBBY seamlessly preserves data with archive management
					so teams can confidently engage project management in the
					platform. We empower teams to complete the full cycle of
					compliance with technology so they can focus on what
					matters: provide greater insight and assurance.
				</div>
			</div>
			<div style={{ width: "2rem" }} />
			<div className="product-image">
				<img
					src={orgProjectsArchived}
					alt="organization archived project list"
				/>
			</div>
		</StyledMainSectionA>
	)
}

const StyledMainSectionA = styled.div`
	background-color: ${props => props.theme.secondaryMain};
	padding-top: 6rem;
	padding-bottom: 5rem;
	@media (min-width: 681px) {
		padding-right: 0;
	}
	.informations {
		border: ${props => props.theme.shared.mockBorder};
		flex-grow: 1;
		width: 100%;
		padding-right: 3rem;
		h1.heading2 {
			margin-top: -1.6rem;
		}
		.description {
		}
	}
	.product-image {
		border: ${props => props.theme.shared.mockBorder};
		flex-grow: 1;
		width: 100%;
		height: 100%;
		img {
			width: 600px;
			margin-right: -3rem;
		}
	}
	@media (max-width: 680px) {
		flex-direction: column;
		justify-content: center;
		.informations {
			margin-bottom: 4rem;
		}
		.product-image {
			height: 400px;
			img {
				height: auto;
				margin: none;
			}
		}
	}
`
