import React from "react"
import styled from "styled-components"

export default function Card({
	title,
	description,
	items,
	icon
}: {
	title: string
	description: string
	items: any
	icon: any
}) {
	return (
		<StyledCard className="FC">
			<div className="up-side FC">
				<div>
					{icon}
					<h1 className="heading2 title">{title}</h1>
				</div>
				<div className="description body-text1">
					{description.toUpperCase()}
				</div>
			</div>
			<div className="bottom-side detail-part">
				{items.map((item: any, i: number) => {
					return (
						<div
							className="detail-item"
							key={item.title.slice(0, 4) + i}
						>
							<div className="detail-item-title body-text1">
								{item.title.toUpperCase()}
							</div>
							<div className="detail-item-contents detail-contents body-text2">
								{item.contents}
							</div>
						</div>
					)
				})}
			</div>
		</StyledCard>
	)
}

const StyledCard = styled.div`
	border: ${props => props.theme.shared.mockBorder};
	width: 100%;
	flex-grow: 1;
	padding: 3rem 2rem;
	@media (max-width: 680px) {
		padding: 0;
		margin-bottom: 6rem;
	}
	.up-side {
		border-bottom: 1px solid gray;
		min-height: 260px;
		justify-content: space-between;

		@media (max-width: 680px) {
			min-height: auto;
		}
	}
	.bottom-side.detail-part {
		display: grid;
		margin-top: 30px;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		grid-column-gap: 24px;
		grid-row-gap: 24px;
		grid-template-rows: auto auto;
		.detail-item {
			border: ${props => props.theme.shared.mockBorder};
			.detail-item-title {
				margin-bottom: 1rem;
				color: ${props => props.theme.primaryDarkDeep};
			}
			.detail-item-contents {
				opacity: 0.8;
			}
		}
	}
`
