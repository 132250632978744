import React from "react"
import styled from "styled-components"
import DLButton from "../DLButton"
import { Link } from "react-router-dom"

export default function MainSection(props: any) {
	const { title, description, image } = props
	return (
		<StyledMainSection className="horizontal-container">
			<div className="image-area">
				<img src={image} alt={title + " image"} />
			</div>
			<div className="info-area FC AL">
				<h1 className="heading1">{title}</h1>
				<div className="body-text1 description">{description}</div>
				<Link to="/request-demo">
					<DLButton color="primary">Request Demo</DLButton>
				</Link>
			</div>
		</StyledMainSection>
	)
}

const StyledMainSection = styled.div`
	background-color: ${props => props.theme.secondaryMain};
	.image-area {
		width: 100%;
		flex-grow: 0.8;
		margin-left: -4rem;
		img {
			width: 600px;
		}
		@media (max-width: 680px) {
			display: none;
		}
	}
	.info-area {
		width: 100%;
		flex-grow: 1.4;
		margin-left: 3rem;
		.description {
			margin-bottom: 3rem;
		}
		@media (max-width: 680px) {
			/* text-align: center; */
			margin-left: 0;
			align-items: center;
			.heading1 {
				width: 70vw;
			}
			.description {
				width: 70vw;
			}
		}
	}
`
