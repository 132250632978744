import React from "react"
import styled from "styled-components"
import ArchDiatnosisScreen from "../../../../assets/screen-proj-arch-diagnosis.jpg"

export default function Features() {
	return (
		<StyledFeatures className="horizontal-container FR">
			<div className="image-part">
				<img
					src={ArchDiatnosisScreen}
					alt="project archive diagnosis screen"
				/>
			</div>
			<div style={{ width: "2rem" }} />

			<div className="informations FC">
				<h1 className="heading2">Build custom project flows</h1>
				<div className="description body-text1">
					Easily create project flows using project templates to match
					your compliance's needs
				</div>
				<div className="partition" />
				<div className="detail detail-contents body-text1">
					Apply Project Template without compromising your original
					way of doing compliance works. With Data LOBBY, you don't
					have to worry about the sudden change of your previous way
					of work with Word and Excel. We are always considering what
					we can do to maximize your efficiency without changing the
					way you work.
				</div>
			</div>
		</StyledFeatures>
	)
}

const StyledFeatures = styled.div`
	background-color: ${props => props.theme.secondaryMain};
	@media (max-width: 680px) {
		flex-direction: column-reverse;
		padding-bottom: 0;
	}
	.image-part {
		width: 100%;
		flex-grow: 1;
		border: ${props => props.theme.shared.mockBorder};
		img {
			/* width: 500px; */
			/* margin-left: -7rem; */
			width: 420px;
		}
		@media (max-width: 680px) {
			margin-top: 4rem;
			width: 100vw;
			img {
				width: 100%;
			}
		}
	}
	.informations {
		width: 100%;
		flex-grow: 1;
		border: ${props => props.theme.shared.mockBorder};

		.partition {
			height: 2px;
			width: 100%;
			background-color: ${props => props.theme.shade05};
			margin: ${prosp => prosp.theme.shared.spacing3} 0;
		}
	}
`
