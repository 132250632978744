import React from "react"
import styled from "styled-components"
import RequestDemoSection from "../request-demo/RequestDemoSection"
import { Icon } from "@mdi/react"
import {
	mdiPowerSleep,
	mdiAbugidaDevanagari,
	mdiAccessPointNetwork
} from "@mdi/js"
import Features from "./sub-components/Features"
import MainSection from "../../common-elements/app-frame/MainSection"
import MiddleBanner from "./sub-components/MiddleBanner"
import doubleScreen from "../../../assets/screen-double.png"

const Products = () => {
	return (
		<StyledProducts className="FC">
			<MainSection
				title="Audit/Reporting/Governance operations that work for you"
				description="DATA LOBBY makes managing compliance projects simple, scalable, and secure."
				image={doubleScreen}
			/>
			<Features />
			{/* <MiddleBanner /> */}
			{/* <Experiences /> */}
			<RequestDemoSection />
		</StyledProducts>
	)
}

const StyledProducts = styled.div``

export default Products

const clients = [
	{
		name: "PLAID"
	}
]

const Experiences = () => {
	return (
		<StyledExperiences className="horizontal-container FC">
			<h2 className="heading2 section-title">
				Powerful integrations keep information flowing between your
				tools
			</h2>
			{clients.map((client: any) => {
				return <div key={client.name}>{client.name}</div>
			})}
		</StyledExperiences>
	)
}

const StyledExperiences = styled.div`
	.section-title {
		width: 50vw;
		text-align: center;
	}
`
