import React, { useRef } from "react"
import styled from "styled-components"
import RequestDemoSection from "../request-demo/RequestDemoSection"
import {
	MainTitle,
	MainSectionA,
	MainSectionB,
	Strength,
	Features
} from "./sub-components"

const Main = () => {
	return (
		<StyledMain className="FC">
			<MainTitle />
			<MainSectionA />
			<MainSectionB />
			<Strength />
			<Features />
			<RequestDemoSection />
		</StyledMain>
	)
}

const StyledMain = styled.div``

export default Main
