import React from "react"
import styled from "styled-components"
import { Link, NavLink } from "react-router-dom"
import { ReactComponent as Logo2 } from "../../../assets/dl-logo-2.svg"
import { mdiCopyright, mdiArrowUpCircleOutline } from "@mdi/js"
import { Icon } from "@mdi/react"
import { useRootStore } from "../../../store/root-store"
import { useObserver } from "mobx-react-lite"

const menus = [
	{
		url: "/products",
		title: "Products",
		subItems: []
	},
	{
		url: "/about-us",
		title: "About Us",
		subItems: []
	},
	{
		url: "/contact",
		title: "Contact",
		subItems: []
	}
]

export default function Footer(props: any) {
	const { scrollZero } = props
	const store = useRootStore()

	return useObserver(() => {
		return (
			<StyledFooter className="FC">
				<div className="upper-side FR">
					<Link to="/" className="dl-logo-container">
						<Logo2 />
					</Link>
					{store.ui.viewInnerWidth() >= 1100 ? (
						<>
							{menus.map((menu: any) => {
								return (
									<NavLink
										to={menu.url}
										className="menu-column"
										activeClassName="active-menu"
										key={menu.url}
									>
										<div className="menu-title">
											{menu.title}
										</div>
										{menu.subItems.map((item: any) => {
											return (
												<div
													className="menu"
													key={item.title}
												>
													{item.title}
												</div>
											)
										})}
									</NavLink>
								)
							})}
						</>
					) : (
						<div
							className="mobile-move-to-top"
							onClick={() => {
								scrollZero.current.focus()
								window.scroll(0, 0)
							}}
						>
							<Icon
								path={mdiArrowUpCircleOutline}
								size={1.6}
								color="lightgray"
							/>
						</div>
					)}
				</div>

				<div className="down-side FR body-text2">
					<Icon
						path={mdiCopyright}
						size={0.8}
						style={{ marginRight: 4 }}
					/>
					2020 Data Lobby
				</div>
			</StyledFooter>
		)
	})
}

const StyledFooter = styled.div`
	border: ${props => props.theme.shared.mockBorder};
	padding: 1rem 10vw;
	/* width: 100vw; */
	/* position: absolute; */
	padding-top: 3rem;
	padding-bottom: 3rem;
	bottom: 0;
	.upper-side {
		@media (max-width: 680px) {
			justify-content: space-between;
			align-items: center;
			.mobile-move-to-top {
				display: none;
				/* padding-top: 0.5rem;
				z-index: 999; */
			}
		}
		.dl-logo-container {
			border: ${props => props.theme.shared.mockBorder};
			margin-right: 6rem;
		}
		.menu-column {
			margin-right: 3rem;
			border: ${props => props.theme.shared.mockBorder};
			&.active-menu {
				color: ${props => props.theme.primaryMain};
			}
			.menu-title {
				margin: 0.6rem 0;
				border: ${props => props.theme.shared.mockBorder};
				.menu {
					border: ${props => props.theme.shared.mockBorder};
				}
			}
		}
	}
	.down-side {
		padding-top: 1rem;
		color: ${props => props.theme.shade40};
		svg {
			fill: ${props => props.theme.shade40};
		}
	}
`
