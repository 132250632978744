import React from "react"
import styled from "styled-components"
import Button from "@material-ui/core/Button"

// for testing storybook, temporarily moved this IButtonProps from separated file.
// after the testing, if the storybook working fine with separated file,
// let's move this Props again to the separated file.

interface IButtonProps {
	children: any
	startIcon?: React.ReactNode
	endIcon?: any
	/** "default" | "primary" | "secondary" | "warning" */
	color?: "default" | "primary" | "secondary" | "warning"
	/** "regular" | "thin" */
	size?: "regular" | "thin"
	disabled?: boolean
	/** "right" | "left" | "center" | "stretched" */
	align?: "right" | "left" | "center" | "stretched"
	clickHandler?: any
	eleTestId?: string
	/** "text" | "outlined" | "contained" */
	variant?: "text" | "outlined" | "contained"
	eleClassName?: string
}

export default function DLButton({
	children = undefined,
	startIcon = undefined,
	endIcon = undefined,
	color = "default",
	size = "regular",
	disabled = undefined,
	align = "center",
	clickHandler = undefined,
	eleTestId = "testId-DLButton",
	variant = "contained",
	eleClassName = undefined
}: IButtonProps) {
	const colorClass = color ? color : "default"
	const sizeClass = size ? size : "regular"
	const isDisabled = disabled ? "disabled" : ""

	return (
		<StyledButton className={`${align}`}>
			<Button
				variant={variant}
				data-testid={eleTestId}
				onClick={clickHandler}
				startIcon={startIcon && startIcon}
				endIcon={endIcon && endIcon}
				className={`button-base ${variant} ${colorClass} ${sizeClass} ${isDisabled} ${eleClassName}`}
			>
				{children}
			</Button>
		</StyledButton>
	)
}

const StyledButton = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	&.right {
		justify-content: flex-end;
	}
	&.left {
		justify-content: flex-start;
	}
	&.stretched {
		justify-content: stretch;
	}
	.button-base {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 1.4rem;
		border-radius: 3px;
		cursor: pointer;
		box-shadow: none;
		transition: 0.2s;
		color: ${props => props.theme.textMediumEmphasis};
		&:hover {
			box-shadow: none;
		}
		&:focus {
			// color:red;
		}
		&.regular {
			height: calc(${props => props.theme.shared.baseUnit} / 1.1);
			font-size: ${props => props.theme.shared.text6};
		}
		&.thin {
			height: calc(${props => props.theme.shared.baseUnit} / 2);
			font-size: ${props => props.theme.shared.text3};
		}
		svg {
			transition: 0.2s;
		}
	}
	/* ========== 1. default color */
	.default {
		/* this basic setting is same as contained */
		background-color: ${props => props.theme.secondaryMain};
		border: 1px solid ${props => props.theme.secondaryMainDeep};
		&:hover {
			background-color: ${props => props.theme.secondaryMainDeep};
		}
		& svg {
			fill: ${props => props.theme.shade60};
		}

		/* ----------- for variant  */
		&.contained {
		}
		&.outlined {
			background-color: transparent;
			border: 1px solid ${props => props.theme.textMediumEmphasis};
			:hover {
				border: 1px solid ${props => props.theme.textHighEmphasis};
				color: ${props => props.theme.textHighEmphasis};
				svg {
					fill: ${props => props.theme.textHighEmphasis};
				}
			}
		}
		&.text {
			background-color: transparent;
            border: transparent;
            :hover {
				color: ${props => props.theme.textHighEmphasis};
				svg {
					fill: ${props => props.theme.textHighEmphasis};
				}
			}
		}
	}

	/* ========== 2. Primary color */
	.primary {
		/* this basic setting is same as contained */
		background-color: ${props => props.theme.primaryMain};
		color: ${props => props.theme.background};
		&:hover {
			background-color: ${props => props.theme.primaryDark};
		}
		& svg {
			fill: ${props => props.theme.primaryMain};
		}

		/* ----------- for variant  */
		&.contained {
			& svg {
				fill: ${props => props.theme.textHighEmphasis};
			}
			:hover {
                /* color: ${props => props.theme.textHighEmphasis}; */
				color: ${props => props.theme.background};
				svg {
					fill: ${props => props.theme.background};
				} 
			}
		}
		&.outlined {
			background-color: transparent;
			border: 1px solid ${props => props.theme.primaryMain};
			color: ${props => props.theme.primaryMain};
			:hover {
				border: 1px solid ${props => props.theme.primaryDark};
				color: ${props => props.theme.primaryDark};
				& svg {
					fill: ${props => props.theme.primaryDark};
				}
			}
		}
		&.text {
			background-color: transparent;
			border: transparent;
            color: ${props => props.theme.primaryMain};
            :hover {
				color: ${props => props.theme.primaryDark};
				svg {
					fill: ${props => props.theme.primaryDark};
				}
			}
		}
	}

	/* ========== 3. secondary color */
	.secondary {
		/* this basic setting is same as contained */
		background-color: ${props => props.theme.primaryLight};
		&:hover {
			background-color: ${props => props.theme.primarylightDeep};
		}
		& svg {
			fill: ${props => props.theme.primaryLight};
		}

		/* ----------- for variant  */
		&.contained {
			& svg {
				fill: ${props => props.theme.textMediumEmphasis};
			}
			:hover {
				background-color: ${props => props.theme.primaryMain};
				color: ${props => props.theme.textHighEmphasis};
				svg {
					fill: ${props => props.theme.textHighEmphasis};
				}
			}
		}
		&.outlined {
			background-color: transparent;
			border: 1px solid ${props => props.theme.primaryLight};
			color: ${props => props.theme.primaryLight};
			:hover {
				border: 1px solid ${props => props.theme.primaryMain};
				color: ${props => props.theme.primaryMain};
				& svg {
					fill: ${props => props.theme.primaryMain};
				}
			}
		}
		&.text {
			background-color: transparent;
			border: transparent;
            color: ${props => props.theme.primaryLight};
            :hover {
				color: ${props => props.theme.primaryMain};
				svg {
					fill: ${props => props.theme.primaryMain};
				}
			}
		}
	}

	/* ========== 4. warning color */
	.warning {
		/* this basic setting is same as contained */
		background-color: ${props => props.theme.warning};
		&:hover {
			background-color: ${props => props.theme.warningDeep};
		}
		& svg {
			fill: ${props => props.theme.warning};
		}

		/* ----------- for variant  */
		&.contained {
			& svg {
				fill: ${props => props.theme.textHighEmphasis};
			}
		}
		&.outlined {
			background-color: transparent;
			border: 1px solid ${props => props.theme.warning};
			color: ${props => props.theme.warning};
			:hover {
				border: 1px solid ${props => props.theme.warningDeep};
				color: ${props => props.theme.warningDeep};
				& svg {
					fill: ${props => props.theme.warningDeep};
				}
			}
		}
		&.text {
			background-color: transparent;
			border: transparent;
            color: ${props => props.theme.warning};
            :hover {
				color: ${props => props.theme.warningDeep};
				svg {
					fill: ${props => props.theme.warningDeep};
				}
			}
		}
	}

	/* ========== 5. disabled */
	.disabled {
		border: none;
		background-color: ${props => props.theme.shade05};
		color: ${props => props.theme.shade10};
		cursor: not-allowed;
		pointer-events: none;
		& svg {
			fill: ${props => props.theme.shade10};
		}
		&:hover {
			background-color: ${props => props.theme.shade05};
			color: ${props => props.theme.shade10};
			& svg {
				fill: ${props => props.theme.shade10};
			}
		}

		/* ----------- for variant  */
		&.contained {
			& svg {
				fill: ${props => props.theme.shade10};
			}
		}
		&.outlined {
			background-color: transparent;
			border: 1px solid ${props => props.theme.shade10};
			color: ${props => props.theme.shade10};
		}
		&.text {
			color: ${props => props.theme.shade10};
		}
	}
`
