import React from "react"
import styled from "styled-components"
import { Icon } from "@mdi/react"
import {
	mdiAccessPointNetwork,
	mdiFileDocumentEditOutline,
	mdiFolderKeyNetworkOutline,
	mdiImageFilterCenterFocusStrong,
	mdiDatabaseLock,
	mdiClockAlertOutline
} from "@mdi/js"

export default function Features() {
	return (
		<StyledFeatures>
			{featureContents.map((feature: any, index: number) => {
				return (
					<Feature
						icon={feature.icon}
						index={index}
						prefix={feature.prefix}
						title={feature.title}
						details={feature.details}
						key={feature.title + index}
					/>
				)
			})}
		</StyledFeatures>
	)
}

const StyledFeatures = styled.div`
	@media (max-width: 680px) {
		padding-bottom: 4rem;
	}
`

const featureContents = [
	{
		icon: <Icon path={mdiFileDocumentEditOutline} size={4.6} />,
		prefix: "Create a project",
		title:
			"Initiate project creation and set the cornerstone for a project",
		details: [
			"Create, verify, and manage project requirements (Client Information, Project Name, Engagement Type, Project Period, Project Owner, etc)",
			"Build custom project workflows by selecting project templates or roll-forward",
			"Schedule expected project completion date and archiving date for real-time alert"
		]
	},
	{
		icon: <Icon path={mdiFolderKeyNetworkOutline} size={4.6} />,
		prefix: "Set up your team",
		title: "Build a team with security and access management in mind",
		details: [
			"Tier permissions and information access by user or team",
			"Temporary invitation of non-project team users to a project with read-only access or collaboration",
			"Investigate user activity history"
		]
	},
	{
		icon: <Icon path={mdiImageFilterCenterFocusStrong} size={4.6} />,
		prefix: "Organize all the project work",
		title: "Consolidate everything that the project matters in one place",
		details: [
			"Online Word & Excel",
			"Multiple Files Upload and Replace",
			"Collaborate and resolve issues",
			"Comprehensive Prepare/Review Sign-off history",
			"Real-time comment",
			"Financial Statement",
			"Task management"
		]
	},
	{
		icon: <Icon path={mdiDatabaseLock} size={4.6} />,
		prefix: "ARCHIVE",
		title:
			"Freeze and archive data that must be retained for future reference or regulatory compliance",
		details: [
			"Digital signature for authenticity and integrity of archived data",
			"Access management on archived projects",
			"Track and audit with a complete log of user actions on accessing archived projects"
		]
	},
	{
		icon: <Icon path={mdiClockAlertOutline} size={4.6} />,
		prefix: "MONITOR",
		title: "Set up alerts, dashboards, and auditing",
		details: [
			"Reporting and dashboards at the project and organization level",
			"Real-time alerts for deadlines in projects and the organization",
			"Track and audit with a complete log of user actions"
		]
	}
]

const Feature = (props: any) => {
	const { icon, index, prefix, title, details } = props

	return (
		<StyledFeature className="horizontal-container FR">
			<div className="icon-area FR AC JC">
				<div className="icon-container FR AC JC">{icon}</div>
			</div>
			<div className="number-area FC">
				<div className="number bg"></div>
				<div className="number FR AC JC heading2">{index + 1}</div>
			</div>
			<div className="info-area FC">
				<div className="body-text1 prefix">{prefix.toUpperCase()}</div>
				<h2 className="heading3">{title}</h2>
				<ul className="body-text2">
					{details.map((detail: string, i: number) => {
						return <li key={i}>{detail}</li>
					})}
				</ul>
			</div>
		</StyledFeature>
	)
}

const StyledFeature = styled.div`
	padding-top: 0;
	padding-bottom: 0;
	@media (max-width: 680px) {
		flex-direction: column;
		padding-top: 4rem;
	}
	.icon-area {
		height: 100%;
		width: 320px;
		padding-right: 5rem;
		@media (max-width: 680px) {
			padding-right: 0;
		}
		.icon-container {
			width: 15rem;
			height: 15rem;
			border-radius: 50%;
			background-color: ${props => props.theme.secondaryMain};
			svg {
				fill: ${props => props.theme.primaryDarkDeep};
			}
		}
	}
	.number-area {
		height: 100%;
		position: relative;
		@media (max-width: 680px) {
			display: none;
		}
		.number {
			position: absolute;
			margin-left: -2.6rem;
			margin-top: -2.6rem;
			width: 5.2rem;
			height: 5.2rem;
			border-radius: 50%;
			background-color: ${props => props.theme.background};
			border: 1px solid gray;

			&.bg {
				width: 6rem;
				height: 6rem;
				margin-left: -3rem;
				margin-top: -3rem;
				border-color: lightgray;
			}
		}
	}
	.info-area {
		height: 100%;
		width: calc(100% - 320px);
		border: ${props => props.theme.shared.mockBorder};
		border-left: 1px solid lightgray;
		padding: 5rem;
		.prefix {
			color: ${props => props.theme.primaryDarkDeep};
		}
		@media (max-width: 680px) {
			width: 100%;
			border-left: none;
			padding: 0;
			margin-top: -3rem;
		}
		ul {
			padding-left: 1rem;
		}
	}
`
