import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link, NavLink, useHistory } from "react-router-dom"
import DLButton from "../DLButton"
import DLMenusWithSingleAction from "../DLMenusWithSingleAction"
import { ReactComponent as Logo } from "../../../assets/dl-logo.svg"
import { ReactComponent as Logo2 } from "../../../assets/dl-logo-2.svg"
import Icon from "@mdi/react"
import { mdiMenu } from "@mdi/js"
import { useRootStore } from "../../../store/root-store"
import { useObserver } from "mobx-react-lite"

export default function Header() {
	const store = useRootStore()

	const menuOptions = [
		{
			label: "Home",
			value: "/"
		},
		{
			label: "Products",
			value: "/products"
		},
		{
			label: "About Us",
			value: "/about-us"
		},
		{
			label: "Contact",
			value: "/contact"
		},
		{
			label: "Request Demo",
			value: "/request-demo"
		}
	]

	let history = useHistory()
	const handleMenu = (selectedValue: any) => {
		history.push(selectedValue)
	}

	return useObserver(() => {
		return (
			<>
				{store.ui.viewInnerWidth() >= 1100 ? (
					<StyledHeader className="FR AC JSB">
						<div className="left-header FR">
							<Link to="/" className="dl-logo-container">
								<Logo2 />
							</Link>

							<NavLink
								to="/products"
								className="menu"
								activeClassName="active-menu"
							>
								Products
							</NavLink>
							<NavLink
								to="/about-us"
								className="menu"
								activeClassName="active-menu"
							>
								About Us
							</NavLink>
							<NavLink
								to="/contact"
								className="menu"
								activeClassName="active-menu"
							>
								Contact
							</NavLink>
						</div>
						<div className="right-header">
							<Link to="/request-demo" className="request-demo">
								<DLButton color="primary">
									Request Demo
								</DLButton>
							</Link>
						</div>
					</StyledHeader>
				) : (
					<StyledHeader className="FR AC JSB">
						<div className="left-header FR">
							<Link to="/" className="dl-logo-container">
								<Logo2 />
							</Link>
						</div>
						<div className="right-header">
							<DLMenusWithSingleAction
								remoteButton={
									<div
										className="FR AC"
										style={{
											height: "100%",
											paddingTop: 8
										}}
									>
										<Icon path={mdiMenu} size={1.4} />
									</div>
								}
								menuOptions={menuOptions}
								handleSelect={handleMenu}
								eleTestId="menus-with-single-action-component"
							/>
						</div>
					</StyledHeader>
				)}
			</>
		)
	})
}

const StyledHeader = styled.div`
	border: ${props => props.theme.shared.mockBorder};
	width: 100vw;
	/* background-color: rgba(255, 255, 255, 0.8); */
	background-color: ${props => props.theme.background};
	position: absolute;
	top: 0;
	padding: 1rem 10vw;
	z-index: 999;
	@media (max-width: 680px) {
		padding: 1rem 6vw;
	}
	.left-header {
		border: ${props => props.theme.shared.mockBorder};
		.dl-logo-container {
			margin-right: 6rem;
			border: ${props => props.theme.shared.mockBorder};
		}
		.menu {
			margin-right: 3rem;
			border: ${props => props.theme.shared.mockBorder};
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: ${props => props.theme.shared.text7};
			padding-top: 0.8rem;
			&.active-menu {
				color: ${props => props.theme.primaryMain};
			}
		}
	}
	.right-header {
		border: ${props => props.theme.shared.mockBorder};
	}
`
