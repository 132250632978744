import sharedTheme from "./shared-theme"

const defaultTheme = {
	// ---------- basic variables (sholud be matched with dark theme)
	shared: sharedTheme,

	background: "#ffffff",
	surface: "",

	// ---------- color standard primary
	primaryMain: "#758fff",
	primaryMainDeep: "#5d72cc",
	primaryLight: "#abbfff",
	primaryLightDeep: "#a1b4f2",
	primaryDark: "#3b62cb",
	primaryDarkDeep: "#2b3b6a",

	// ---------- color standard secondary
	secondaryMain: "#f5f7f9",
	secondaryMainDeep: "#e8edf2",
	secondaryLight: "#ffffff",
	secondaryDark: "#c2c4c6",

	// ---------- project type color standard
	normalProject: "#6D7278", // similar with textMediumEmphasis
	normalLockedProject: "#F5BE40", //
	replicaProject: "#C2C4C6", // similar with secondaryDark
	archivedProejct: "#800180",
	unarchivedProject: "#DA5D4D", // similar with warning

	// ---------- warning and dangerous
	warning: "#ff7147",
	warningDeep: "#e14e21",

	// ---------- text standard
	onBackground: "",
	onSurface: "",
	onPrimary: "",
	onSecondary: "",
	textHighEmphasis: "rgba(0,0,0,.85)",
	textMediumEmphasis: "rgba(0,0,0,.60)",
	textDisabled: "rgba(0,0,0,.35)",

	// ---------- shade
	shade05: "rgba(0,0,0,.05)",
	shade10: "rgba(0,0,0,.1)",
	shade20: "rgba(0,0,0,.2)",
	shade40: "rgba(0,0,0,.4)",
	shade60: "rgba(0,0,0,.6)",

	// ---------- shadow
	shadowColorLight: "rgba(192, 203, 220, 0.6)",
	shadowColorDeep: "rgba(192, 203, 220, 0.8)",
	shadowRegularRightLight: "2px 0px 10px 1px rgba(192, 203, 220, 0.6)",
	shadowRegularLeftLight: "-2px 0px 10px 1px rgba(192, 203, 220, 0.6)",
	shadowRegularRightDeep: "2px 0px 10px 1px rgba(192, 203, 220, 0.8)",
	shadowRegularLeftDeep: "-2px 0px 10px 1px rgba(192, 203, 220, 0.8)"
}

export default defaultTheme
