import React from "react"
import styled from "styled-components"
import RequestDemoSection from "../request-demo/RequestDemoSection"

const AboutUs = () => {
	return (
		<StyledAboutUs>
			<AboutUsMain />
			<AboutUsDetail />
			<RequestDemoSection />
		</StyledAboutUs>
	)
}

const StyledAboutUs = styled.div``

export default AboutUs

const AboutUsMain = () => {
	return (
		<StyledAboutUsMain className="horizontal-container FR">
			<div className="container">
				<h1 className="heading1">About Us</h1>
				<div className="body-text1">
					Datalobby builds an integrated and secured compliance data
					platform. Our tools integrate the full cycle of financial
					reporting-from financial closing, through internal audit, to
					external audit-and minimize the risk of audit/reporting and
					data protection in one single all-in-one platform.
				</div>
			</div>
		</StyledAboutUsMain>
	)
}

const StyledAboutUsMain = styled.div`
	background-color: ${props => props.theme.secondaryMain};
	justify-content: flex-start;
	.container {
		width: 50%;
	}
	@media (max-width: 680px) {
		justify-content: center;
		.container {
			width: 70vw;
			text-align: center;
		}
	}
`

const contents = [
	{
		title: "BUILDING FOR FINANCIAL REPORTING COMPLIANCE TOGETHER",
		paragraphs: [
			"Benjamin and Boseok were responsible for integrated audits of SEC companies at Pwc, Ernst&Young, and KPMG over 20 years.",
			"With a deep understanding of internal control of financial reporting in the US, the team is helping more than 15 non-Big 4 audit firms and more than 55% of non-Big 4 CPAs in Korea. Starting in January 2020, the team is also helping Chartered Accountants in India with a fast adoption rate."
		]
	},
	{
		title: "EMPOWERING CPA/CA PROFESSIONALS",
		paragraphs: [
			"After Ben, Bo, and Noah realized that thousands of companies are looking for a new level of consultancy from CPA/CA professionals, often not equipped with an adequate system for high-quality accounting service, they set out to build a more flexible and scalable solution called Datalobby compliance data platform.",
			"The team completed the RBS Accelerator Program, and since then the team is building service for CPA/CA professionals and Enterprises in the UK and India as well."
		]
	}
]

const AboutUsDetail = () => {
	return (
		<StyledAboutUsDetail className="horizontal-container">
			{contents.map((content: any, i: number) => {
				return (
					<div
						className="detail-card FC"
						key={content.title.slice(0, 4) + i}
					>
						<div className="sub-title body-text1">
							{content.title.toUpperCase()}
						</div>
						<div className="body-text2">
							{content.paragraphs.map((p: string, i: number) => {
								return <p key={p.slice(0, 4) + i}>{p}</p>
							})}
						</div>
					</div>
				)
			})}
		</StyledAboutUsDetail>
	)
}

const StyledAboutUsDetail = styled.div`
	div:first-child {
		margin-right: 4rem;
	}
	.detail-card {
		padding-right: 3rem;
		.sub-title {
			color: ${props => props.theme.primaryMain};
		}
	}
	@media (max-width: 680px) {
		flex-direction: column;
		div:first-child {
			margin-right: 0;
		}
		.detail-card {
			margin-bottom: 4rem;
		}
	}
`
