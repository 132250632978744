import React from "react"
import styled from "styled-components"
import { MessageType } from "./MessageType"
import Icon from "@mdi/react"
import {
	mdiCheckboxMarkedCircleOutline,
	mdiAlertCircleOutline,
	mdiAlert,
	mdiAlertDecagram,
	mdiInformationVariant
} from "@mdi/js"

interface DLSystemMsgProps {
	msg: string
	/** DEFAULT | BLUE | ORANGE | CORAL | RED */
	type?: MessageType
	/** alert */
	eleRole?: string
	eleTestId?: string
}
const DLSystemMsg = ({
	msg = "",
	type = MessageType.default,
	eleRole = undefined,
	eleTestId = "testId-DLSystemMsg"
}: DLSystemMsgProps) => {
	return (
		<StyledDLSystemMsg
			className={`FR AC ${type}`}
			role={eleRole}
			data-testid={eleTestId}
		>
			{type === MessageType.default ? (
				<Icon
					path={mdiInformationVariant}
					size={0.8}
					className="icon"
				/>
			) : type === MessageType.blue ? (
				<Icon
					path={mdiCheckboxMarkedCircleOutline}
					size={0.8}
					className="icon"
				/>
			) : type === MessageType.orange ? (
				<Icon
					path={mdiAlertCircleOutline}
					size={0.8}
					className="icon"
				/>
			) : type === MessageType.coral ? (
				<Icon path={mdiAlert} size={0.8} className="icon" />
			) : type === MessageType.red ? (
				<Icon path={mdiAlertDecagram} size={0.8} className="icon" />
			) : (
				""
			)}
			{msg}
		</StyledDLSystemMsg>
	)
}

export default DLSystemMsg

const StyledDLSystemMsg = styled.div`
	&.BLUE {
		color: ${props => props.theme.shared.messageBlue};
		svg {
			fill: ${props => props.theme.shared.messageBlue};
		}
	}
	&.ORANGE {
		color: ${props => props.theme.shared.messageOrange};
		svg {
			fill: ${props => props.theme.shared.messageOrange};
		}
	}
	&.CORAL {
		color: ${props => props.theme.shared.messageCoral};
		svg {
			fill: ${props => props.theme.shared.messageCoral};
		}
	}
	&.RED {
		color: ${props => props.theme.shared.messageRed};
		svg {
			fill: ${props => props.theme.shared.messageRed};
		}
	}
	.icon {
		margin-right: 0.4rem;
	}
`
