import React from "react"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import DLRadio from "./DLRadio"
import styled from "styled-components"

interface DLRadioGroupProps {
	formTitle?: string
	items: { value: string; label: string }[] // value and label
	eleName?: string
	eleValue: any
	eleOnChange?: React.EventHandler<any>
	// changeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void
	formHelpText?: string
	/** "row" | "column" */
	itemDirection?: "row" | "column"
	/** "start" | "end" */
	labelPlacement?: "start" | "end"
	color?: "default" | "secondary" | "primary"
	eleClassName?: string
	size?: "small" | "medium" | "large"
	useInternalLabel?: boolean
}

/**
 * row-direction needs more update
 */

export default function DLRadioGroup({
	formTitle = undefined,
	items = [
		{
			value: "",
			label: ""
		}
	],
	eleName = undefined,
	eleValue = undefined,
	eleOnChange = undefined,
	formHelpText = undefined,
	itemDirection = "column",
	labelPlacement = "end",
	color = "primary",
	eleClassName = undefined,
	size = "medium",
	useInternalLabel = false
}: DLRadioGroupProps) {
	return (
		<StyledDLRadioGroup className={eleClassName}>
			<FormControl>
				<FormLabel>{formTitle}</FormLabel>
				<div style={{ height: 10 }} />
				<RadioGroup
					aria-label={eleName}
					// name={eleName}
					name="xxx?"
					value={eleValue}
					onChange={eleOnChange}
					style={{
						flexDirection: itemDirection,
						paddingLeft: "1rem"
					}}
				>
					{items.map((item: any) => {
						const { value, label } = item
						return (
							<FormControlLabel
								key={value}
								value={value}
								name="x?"
								control={
									<DLRadio
										color={color}
										eleTestId={label + "-" + value}
										size={size}
										label={useInternalLabel && label}
										labelPlacement={labelPlacement}
									/>
								}
								label={useInternalLabel ? "" : label}
								className={`${size} ${itemDirection === "row" &&
									"row-direction"}`}
								labelPlacement={labelPlacement}
							/>
						)
					})}
				</RadioGroup>
				<FormHelperText>{formHelpText}</FormHelperText>
			</FormControl>
		</StyledDLRadioGroup>
	)
}

const StyledDLRadioGroup = styled.div`
	margin-top: 3rem;
	div[aria-label="radio-group"] {
		> label {
			display: flex;
			align-items: center;

			&.small {
				margin-bottom: ${props => props.theme.shared.spacingSS};
				&.row-direction {
					margin-right: ${props => props.theme.shared.spacing3};
				}
			}
			&.medium {
				margin-bottom: ${props => props.theme.shared.spacingSS};
				&.row-direction {
					margin-right: ${props => props.theme.shared.spacing3};
				}
			}
			&.large {
				margin-bottom: ${props => props.theme.shared.spacingS};
				&.row-direction {
					margin-right: ${props => props.theme.shared.spacing3};
				}
			}
		}
	}
`
