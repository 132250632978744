const sharedTheme = {
	mockBorder: "1px solid transparent",
	/* ---------- font-size ---------- */
	text10: "3rem", // 48px
	text9: "2rem", // 32px
	text8: "1.25rem", // 20px
	text7: "1.125rem", // 18px
	text6: "1rem", // 16px
	text5: "0.875rem", // 14px
	text4: "0.75rem", // 12px
	text3: "0.7rem", // 11px
	text2: "0.6rem", // 9px
	text1: "0.5rem", // 8px

	/* ---------- font-weight ---------- */
	fontLight: 300,
	fontRegular: 400,
	fontMedium: 500,
	fontBold: 600,
	fontBlack: 900,

	/* ---------- spacing ---------- */
	spacingWide: "4rem",
	spacingNormal: "2rem",
	spacingThin: "1rem",
	//
	spacing5: "4rem",
	spacing4: "3rem",
	spacing3: "2rem",
	spacing2: "1.5rem",
	spacing1: "1rem",
	//
	spacingS: "0.5rem",
	spacingSS: "0.25rem",

	/* ---------- component standard ---------- */
	baseUnit: "48px",

	// ---------- message colors
	messageBlueAutoHide: "rgba(171,191,255,0.5)",
	messageOrangeAutoHide: "rgba(245,190,64,0.6)",
	messageCoralAutoHide: "rgba(218,93,77,0.5)",
	messageRedAutoHide: "rgba(219,0,38,0.5)", // NOTE: DO NOT USE autohide for red type message!
	//
	messageBlue: "#ABBFFF",
	messageOrange: "#F5BE40",
	messageCoral: "#DA5D4D",
	messageRed: "#DB0026"
}

export default sharedTheme
