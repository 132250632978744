import React, { useState } from "react"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiEmailEditOutline } from "@mdi/js"
import DLInputField from "../../common-elements/DLInputField"
import DLTextArea from "../../common-elements/DLTextArea"
import { TextField } from "@material-ui/core"
import DLButton from "../../common-elements/DLButton"
import DLRadioGroup from "../../common-elements/DLRadioGroup"
import * as emailjs from "emailjs-com"
import DLSpinner from "../../common-elements/DLSpinner"

const RequestDemo = () => {
	/**
	 * first name:
	 * last name:
	 * email:
	 * business type:
	 * company name:
	 * message:
	 */

	// states

	const initialState = {
		firstName: "",
		lastName: "",
		email: "",
		businessType: "accounting-firm",
		companyName: "",
		message: ""
	}
	const [state, setState] = useState(initialState)
	const [actionState, setActionState] = useState("standby")
	const [emailFormatError, setEmailFormatError] = useState<string>("")
	// handlings
	const handleInput = (event: any) => {
		setState({ ...state, [event.target.name]: event.target.value })
	}
	const handleRadio = (event: any) => {
		setState({ ...state, businessType: event?.target.value })
	}
	const handleEmailInput = (event: any) => {
		setState({ ...state, email: event.target.value })
		const emailFormat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/
		if (!emailFormat.test(event.target.value)) {
			setEmailFormatError("Email format is invalid")
		} else {
			setEmailFormatError("")
		}
	}

	// submit
	const handleSubmit = (event: any) => {
		event.preventDefault()
		setActionState("loading")
		emailjs
			.send(
				"datalobby2020",
				"requestdemo2020",
				state,
				"user_GJu4VG7icagwBvlytW6ml"
			)
			.then((response: any) => {
				// alert(response.text)
				// setState(initialState)
				setActionState("success")
			})
			.catch((error: Error) => {
				console.log("error", error)
				alert(
					"Something wrong... Please try again. If the error be reproduced, please contact to info@datalobby.com directly."
				)
			})
	}

	return (
		<StyledRequestDemo className="FR AC JC">
			<div className="request-container FC AC">
				<Icon path={mdiEmailEditOutline} size={3} />
				<h2 className="section-title heading2">
					See the DATA LOBBY Solution
				</h2>
				{actionState === "standby" && (
					<div className="form">
						<DLInputField
							eleName="firstName"
							eleTestId="first-name-input"
							eleLabel="First Name"
							elePlaceholder="Please input your first name"
							eleFullWidth
							eleClassName="input-item"
							eleRequired
							eleValue={state.firstName}
							eleHandleChange={handleInput}
						/>
						<DLInputField
							eleName="lastName"
							eleTestId="last-name-input"
							eleLabel="Last Name"
							elePlaceholder="Please input your last name"
							eleFullWidth
							eleClassName="input-item"
							eleValue={state.lastName}
							eleHandleChange={handleInput}
						/>
						<DLInputField
							eleName="email"
							eleTestId="email-input"
							eleLabel="Email"
							elePlaceholder="Please input your email address"
							eleFullWidth
							eleClassName="input-item"
							eleRequired
							eleValue={state.email}
							eleHandleChange={handleEmailInput}
							warningMsg={emailFormatError}
						/>

						<DLRadioGroup
							formTitle="Business Type"
							eleName="businessType"
							items={[
								{
									value: "accounting-firm",
									label: "Accounting Firm"
								},
								{
									value: "enterprise",
									label: "Enterprise"
								}
							]}
							eleValue={state.businessType}
							eleOnChange={handleRadio}
							eleClassName="input-item"
						/>
						<DLInputField
							eleName="companyName"
							eleTestId="company-name-input"
							eleLabel="Company Name"
							elePlaceholder="Please input your company name"
							eleFullWidth
							eleClassName="input-item"
							eleValue={state.companyName}
							eleHandleChange={handleInput}
						/>
						<DLTextArea
							eleName="message"
							eleTestId="message-input"
							eleLabel="Tell us more"
							elePlaceholder="Please input your message about some detail requirements or questions"
							eleFullWidth
							eleClassName="input-item"
							eleFieldHeight={8}
							eleValue={state.message}
							eleHandleChange={handleInput}
						/>
						<DLButton
							color="primary"
							clickHandler={handleSubmit}
							disabled={emailFormatError ? true : false}
						>
							Send
						</DLButton>
						<p style={{ textAlign: "center" }}>
							{emailFormatError}
						</p>
					</div>
				)}
				{actionState === "loading" && <DLSpinner />}
				{actionState === "success" && (
					<div style={{ textAlign: "center" }}>
						<p className="heading3">Success!</p>
						<p>Our team will response to you as soon as possible</p>
						<p>Your submitted email address is: {state.email}</p>
						<p>Please try again if your email address is wrong. </p>
						<p>
							Or you can contact to us with info@datalobby.com
							directly
						</p>
						<p>Thank you.</p>
					</div>
				)}
			</div>
		</StyledRequestDemo>
	)
}

const StyledRequestDemo = styled.div`
	background-color: ${props => props.theme.secondaryMain};
	padding: 5rem 0;
	.request-container {
		background-color: ${props => props.theme.background};
		width: 36vw;
		min-width: 320px;
		padding: 3rem 4rem;
		box-shadow: 0px 2px 10px 1px ${props => props.theme.shadowColorLight};
		@media (max-width: 680px) {
			background-color: transparent;
			padding-top: 0;
			width: 100%;
			box-shadow: none;
			.form {
				.input-item {
					margin-bottom: 2rem;
				}
			}
		}
		.section-title {
			text-align: center;
		}
		.form {
			width: 100%;
			.input-item {
				margin-bottom: 1.6rem;
			}
		}
	}
`

export default RequestDemo
