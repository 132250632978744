import React, { useEffect, useRef, useState } from "react"
import {
	BrowserRouter as Router,
	Switch,
	Route,
	useLocation
} from "react-router-dom"
import "./App.scss"
// frame
import PageContainer from "./components/common-elements/app-frame/PageContainer"
// components
import Main from "./components/screens/main/Main"
import AboutUs from "./components/screens/about-us/AboutUs"
import Products from "./components/screens/products/Products"
import Contact from "./components/screens/contact/Contact"
import RequestDemo from "./components/screens/request-demo/RequestDemo"
import { ThemeProvider } from "styled-components"
import defaultTheme from "./styles/default-theme"

function App() {
	return (
		<Router>
			<ThemeProvider theme={defaultTheme}>
				<PageContainer>
					<Switch>
						<Route exact path="/" component={Main} />
						<Route exact path="/main" component={Main} />
						<Route exact path="/about-us" component={AboutUs} />
						<Route exact path="/products" component={Products} />
						<Route exact path="/contact" component={Contact} />
						<Route
							exact
							path="/request-demo"
							component={RequestDemo}
						/>
					</Switch>
				</PageContainer>
			</ThemeProvider>
		</Router>
	)
}

export default App
