import React from "react"
import styled from "styled-components"
import Icon from "@mdi/react"
import {
	mdiAccount,
	mdiCurrencyUsd,
	mdiMagnify,
	mdiKeyRemove,
	mdiCursorMove
} from "@mdi/js"

export default function MainSectionB() {
	return (
		<StyledMainSectionB className="horizontal-container FC">
			<h2 className="section-title heading3">
				Work on projects whichever you want
			</h2>
			<div className="items FR">
				<div className="item body-text1">
					<div className="icon-container">
						<Icon path={mdiMagnify} size={1} />
					</div>
					AUDIT
				</div>
				<div className="item body-text1">
					<div className="icon-container">
						<Icon path={mdiCurrencyUsd} size={1} />
					</div>
					TAX
				</div>
				<div className="item body-text1">
					<div className="icon-container">
						<Icon path={mdiAccount} size={1} />
					</div>
					PRIVATE ACCOUNTING
				</div>
				<div className="item body-text1">
					<div className="icon-container">
						<Icon path={mdiCursorMove} size={1} />
					</div>
					INTERNAL CONTROL
				</div>
			</div>
		</StyledMainSectionB>
	)
}

const StyledMainSectionB = styled.div`
	background-color: ${props => props.theme.primaryDarkDeep};
	.section-title {
		color: ${props => props.theme.background};
		border: ${props => props.theme.shared.mockBorder};
		@media (max-width: 680px) {
			max-width: 320px;
			text-align: center;
			margin-bottom: 2rem;
		}
	}
	.items {
		border: ${props => props.theme.shared.mockBorder};
		color: ${props => props.theme.background};
		@media (max-width: 680px) {
			flex-direction: column;
			width: 100%;
			/* justify-content: center; */
			/* flex-wrap: wrap; */
		}
		.item {
			display: flex;
			align-items: center;
			margin: ${props => props.theme.shared.spacing2};
			border: ${props => props.theme.shared.mockBorder};
			@media (max-width: 680px) {
				margin: ${props => props.theme.shared.spacing1};
			}
			.icon-container {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-right: 0.6rem;
			}
			svg {
				fill: ${props => props.theme.background};
			}
		}
	}
`
