import React, { useState } from "react"
import Radio from "@material-ui/core/Radio"
import styled from "styled-components"

interface DLRadioProps {
	checked?: boolean
	label?: string
	value?: any
	color?: "default" | "secondary" | "primary"
	disabled?: boolean
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	size?: "small" | "medium" | "large"
	eleTestId: string
	labelPlacement?: "start" | "end"
}

/**
 * Radio buttons for single selection
 *
 * Need more configuration
 *
 * <strong>DO NOT</strong> TRY TO USE SINGLE RADIO BUTTON
 * <br /> Radio buttons are for selection not toggle
 *
 * #### Note:
 * Basically single raido button has no label itself.
 * <br/> but for consistency on the style, takes label to display
 * <br/> `style-label` property was added to block the 'Mui-' classNames.
 *
 */

export default function DLRadio({
	checked = undefined,
	label = undefined,
	value = undefined,
	color = "default",
	disabled = undefined,
	onChange = undefined,
	size = "medium",
	eleTestId = "testId-DLRadio",
	labelPlacement = "end"
}: DLRadioProps) {
	return (
		<StyledRadio className="FR AC dl-radio-style">
			{labelPlacement === "start" && (
				<span
					className={`radio-name place-start ${disabled &&
						"color-disabled"} label-size-${size}`}
				>
					{label}
				</span>
			)}
			<div className={`radio-area ${size} ${color}`}>
				<Radio
					checked={checked}
					onChange={onChange}
					value={value}
					color="default" // do not use props here
					name={label}
					inputProps={{
						"aria-label": label ? "radio-" + label : "radio-button"
					}}
					disabled={disabled}
					data-testid={eleTestId}
					style-label="dl-radio"
					className={`radio size-${size} color-${color}`}
				/>
			</div>
			{labelPlacement === "end" && (
				<span
					className={`radio-name place-end ${disabled &&
						"color-disabled"} label-size-${size}`}
				>
					{label}
				</span>
			)}
		</StyledRadio>
	)
}

const StyledRadio = styled.div`
	span[style-label="dl-radio"].radio {
		margin: 0;
		padding: 0;
		span {
			/** for reference */
			/** this part is "MuiIconButton-label" */
			div {
				/** this part is a container of svg*/
				svg {
					/** these svg has name: MuiSvgIcon-root */
				}
			}
		}

		&[aria-disabled="true"] {
			color: ${props => props.theme.shade10};
		}
		&.color-secondary {
			div {
				svg {
					color: ${props => props.theme.primaryMain};
				}
			}
		}
		&.color-primary {
			div {
				svg {
					color: ${props => props.theme.primaryDark};
				}
			}
		}
		&.size-small {
			div {
				svg {
					width: 1rem;
					height: 1rem;
				}
			}
		}
		&.size-medium {
			svg {
				width: 1.25rem;
				height: 1.25rem;
			}
		}
		&.size-large {
			svg {
				width: 1.5rem;
				height: 1.5rem;
			}
		}
	}
	span.radio-name {
		&.place-start {
			margin-right: 0.4rem;
		}
		&.place-end {
			margin-left: 0.4rem;
		}

		&.color-disabled {
			color: ${props => props.theme.shade20};
		}
		&.label-size-medium {
			font-size: ${props => props.theme.shared.textXl};
			&.place-start {
				margin-right: 0.4rem;
			}
			&.place-end {
				margin-left: 0.4rem;
			}
		}
		&.label-size-large {
			font-size: ${props => props.theme.shared.textXxl};
			&.place-start {
				margin-right: 0.6rem;
			}
			&.place-end {
				margin-left: 0.6rem;
			}
		}
	}
`
