import React from "react"
import styled from "styled-components"
import OfficeMap_KR from "../../../assets/kr-office.jpg"
import OfficeMap_US from "../../../assets/us-office.jpg"

const offices = [
	{
		country: "U.S.",
		address: "200 Sandpointe Ave #560, Santa Ana, CA 92707 USA",
		phone: "+1 . 714 . 238 . 0000",
		email: "info@datalobby.com",
		map: OfficeMap_US
	},
	{
		country: "Korea",
		address: "서울특별시 서초구 헌릉로 7 IKP 715 IKP 809 (06792)",
		phone: "+82 . 2 . 3497 . 1888",
		email: "info@datalobby.co.kr",
		map: OfficeMap_KR
	}
]

const Contact = () => {
	return (
		<StyledContact className="FC AC horizontal-section">
			{offices.map((office: any) => {
				return (
					<div className="office-info FR" key={office.address}>
						<div className="informations">
							<h3 className="heading3">
								{office.country} Office
							</h3>
							<div className="body-text2">{office.address}</div>
							<div className="body-text2">{office.phone}</div>
							<div className="body-text2">{office.email}</div>
						</div>
						<div className="map">
							<img
								src={office.map}
								alt="datalobby US office map"
								style={{ width: 500 }}
							/>
						</div>
					</div>
				)
			})}
		</StyledContact>
	)
}

const StyledContact = styled.div`
	border: ${props => props.theme.shared.mockBorder};
	padding: 0 10vw;
	.office-info {
		margin-top: 4rem;
		margin-bottom: 4rem;
		width: 60vw;
		.informations {
			border: ${props => props.theme.shared.mockBorder};
			width: 100%;
			flex-grow: 1;
			padding-right: 3rem;
		}
		.map {
			border: ${props => props.theme.shared.mockBorder};
			width: 100%;
			flex-grow: 1;
		}
	}
	@media (max-width: 680px) {
		padding: 0 0;
		.office-info {
			width: 100%;
			flex-direction: column;
			margin-top: 1rem;
			margin-bottom: 3rem;
			.informations {
				padding-left: 6vw;
				padding-bottom: 1rem;
			}
		}
	}
`

export default Contact
