import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import Header from "./Header"
import Footer from "./Footer"
import { useLocation } from "react-router-dom"
import { useRootStore } from "../../../store/root-store"

const PageContainer = ({ children }: { children: any }) => {
	const store = useRootStore()
	const scrollZero = useRef<any>(null)
	const { pathname } = useLocation()

	window.onresize = resize
	// let initialScreenWidth = window.innerWidth
	// const [screenWidth, setScreenWidth] = useState(initialScreenWidth)

	function resize() {
		// console.log("window resize", window.innerWidth)
		// setScreenWidth(window.innerWidth)
		store.ui.setInnerWidth(window.innerWidth)
	}

	useEffect(() => {
		scrollZero.current.focus()
		store.ui.setInnerWidth(window.innerWidth)
		window.scroll(0, 0)
	}, [pathname])

	return (
		<StyledPageContainer className="page-container">
			<input
				ref={scrollZero}
				id="scroll-zero"
				disabled={store.ui.innerWidth <= 480}
			/>
			<Header />
			<div style={{ paddingTop: "64px" }}>{children}</div>
			<Footer scrollZero={scrollZero} />
		</StyledPageContainer>
	)
}

const StyledPageContainer = styled.div`
	height: 100vh;
	overflow-y: scroll;
	overflow-x: hidden;
	/* display: flex;
	flex-direction: column; */
	input#scroll-zero {
		opacity: 0;
		width: 0;
		height: 0;
		overflow: hidden;
		margin: 0;
		padding: 0;
		border: none;
	}
	@media (max-width: 680px) {
		input#scroll-zero {
			display: none;
		}
	}
`

export default PageContainer
