import React from "react"
import styled from "styled-components"
import DLButton from "../../../common-elements/DLButton"
import { ReactComponent as LogoIconOnly } from "../../../../assets/dl-logo-icon-only.svg"
import { Link } from "react-router-dom"

export default function MainTitle() {
	return (
		<StyledMainTitle className="horizontal-container FC JC AC">
			<div className="logo-icon">
				<LogoIconOnly />
			</div>
			<h1 className="title heading1">
				The single source of truth to manage Auditors and Enterprises's
				audit/reporting/governance process
			</h1>
			<h2 className="description body-text1">
				Automate and manage the full cycle of compliance data-from file
				management and approvals(prepare/review) to access and archive
				management-all in one platform.
			</h2>
			<Link to="/request-demo">
				<DLButton color="primary">Request Demo</DLButton>
			</Link>
		</StyledMainTitle>
	)
}

const StyledMainTitle = styled.div`
	border: ${props => props.theme.shared.mockBorder};
	.logo-icon {
		border: ${props => props.theme.shared.mockBorder};
		padding: 2rem 0;
		width: 4.5rem;
		svg {
			width: 100%;
			height: 100%;
		}
	}
	.title {
		border: ${props => props.theme.shared.mockBorder};
		width: 55vw;
		text-align: center;
		word-break: break-word;
	}
	.description {
		border: ${props => props.theme.shared.mockBorder};
		width: 55vw;
		text-align: center;
		margin-bottom: 3rem;
	}
	@media (max-width: 680px) {
		.title {
			width: 70vw;
		}
		.description {
			width: 70vw;
		}
	}
`
