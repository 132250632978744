import React from "react"
import styled from "styled-components"
import Card from "./Card"
import { mdiCrosshairsGps, mdiFlash } from "@mdi/js"
import { Icon } from "@mdi/react"

export default function Strength() {
	const items1 = [
		{
			title: "GOVERNANCE AT SCALE",
			contents:
				"Centralized data management per each project across all clients give CPA/CA professionals a single source of truth"
		},
		{
			title: "CONTROL",
			contents:
				"Manage complete prepare/review approvals with the comprehensive sign-off history by creating archiving policy"
		},
		{
			title: "AUDIT TRAIL",
			contents:
				"Keep data attached to the attestation process for complete, accurate audit trail"
		},
		{
			title: "AUTOMATION OF ARCHIVING",
			contents:
				"Archiving diagnosis makes the documentation closure and archiving process real-time"
		}
	]

	const items2 = [
		{
			title: "MULTI-PURPOSE",
			contents:
				"A robust, scalable, connected financial reporting/compliance service means you will meet your goal with cost optimization"
		},
		{
			title: "CENTRAL OPERATION",
			contents:
				"One place for financial reporting, prepare/review approvals, archiving, and internal control"
		},
		{
			title: "QUICK INTEGRATION",
			contents:
				"Go live in days, not months, with user-friendly applications"
		},
		{
			title: "BUILT-IN DOMAIN EXPERTISE",
			contents:
				"Created by BIG4 alumni who have comprehensive experience of SEC integrated audits, including financial statement audits and internal control of financial reporting"
		}
	]

	return (
		<StyledStrength className="horizontal-container FR">
			<Card
				title="AUDIT LOBBY for Auditors"
				description="SECURITY AND COMPLIANCE"
				items={items1}
				icon={<Icon path={mdiCrosshairsGps} size={2} />}
			/>
			<div style={{ width: "2rem" }} />
			<Card
				title="Accounting/SOX LOBBY for Enterprises"
				description="COST OPTIMIZATION"
				items={items2}
				icon={<Icon path={mdiFlash} size={2} />}
			/>
		</StyledStrength>
	)
}

const StyledStrength = styled.div`
	align-items: flex-start;
	@media (max-width: 680px) {
		flex-direction: column;
		padding-bottom: 0;
	}
`
